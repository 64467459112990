<template>
  <div class="bg-dark px-3 py-4 mb-2 rounded position-relative" data-aos="fade-right" data-aos-duration="500">
    <!-- icon -->
    <div class="position-absolute" style="top: 0 !important; right: 0 !important">
      <vs-button flat icon success to="/helex/hoja_vida">
        <i class="fas fa-id-card"></i>
      </vs-button>
    </div>
    <div class="row">
      <!-- agregar foto de perfil -->
      <div class="col-12 col-lg-2 d-flex justify-content-center" style="position: relative; align-items: center !important">
        <div class="circle-container">
          <vs-tooltip dark>
            <label class="circle">
              <img :src="usuario.imagenPerfilUrl" v-show="usuario.imagenPerfilUrl" data-aos="zoom-in" class="img-fluid bg-img-user" alt="imagen_perfil" />
              <div class="icon" v-show="!usuario.imagenPerfilUrl">
                <i class="fas fa-user"></i>
              </div>
            </label>
          </vs-tooltip>
        </div>
      </div>
      <!-- info profile -->
      <div class="col-12 col-lg d-flex flex-column justify-content-between">
        <!-- nombre usuario y rol -->
        <div>
          <h3 class="text-uppercase" style="font-weight: 700">
            {{ usuario.nombres + " " + usuario.apellidos }}
          </h3>
          <h4 class="text" style="font-size: 700">Gerente</h4>
          <br />
          <span class="text-white-60"> <b>Hombre </b> de <b>25</b> años </span><br />
          <span class="text-white-60"> <b>CC: </b> 0123456789 </span><br />
          <span class="text-white-60"> <b>CEL: </b> 3012345678 </span><br />
          <span class="text-white-60"> <b>CORREO: </b>administrador@helexium.com </span>
          <!-- <span v-if="estadisticasProfile.cargo" class="text-white-50">
            {{ estadisticasProfile?.cargo }}  
          </span> -->
        </div>
        <!-- info details  -->
        <div class="d-flex justify-content-between flex-wrap" style="gap: 1rem"></div>
      </div>
    </div>
  </div>
</template>
<script>
// const Swal = require("sweetalert2");

export default {
  components: {},
  data() {
    return {
      usuario: {},
      file: null,
      filename: null,
      estadisticasProfile: {
        empresaId: null,
        personaId: null,
        departamentoId: null,
        cargoId: null,
        nombre_empresa: null,
        departamento: null,
        nombreEvaluado: null,
        cargo: null,
        pulsoEmpresa: 0,
        pulsoDepartamento: 0,
        pulsoPersona: 0,
      },
      personaUbicacion: {
        cargo: {
          nombre: "Cargo",
        },
      },
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      const { id, empresa } = this.usuario;
      await this.getEstadisticasProfile(id, empresa.id);
      // console.log("estadisticas",estadisticasProfile.cargo)
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    //  async getPersonaUbicacion(personaId) {
    //   try {
    //     let res = await this.$store.dispatch("hl_get", {
    //       path: "PersonaUbicacion/GetByPersona/" + personaId,
    //     });
    //     console.log("persona ubicacion...", res);
    //     if (res.cargo) {
    //       this.personaUbicacion = res;
    //     }
    //   } catch (error) {
    //     console.log("err", error);
    //   }
    // },
  },
};
</script>
<style scoped>
/* agregar imagen perfil */
.circle-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.circle {
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  border: 2px solid rgb(242, 19, 93);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  transition: all 1s ease;
}

.circle input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.circle:hover {
  /* glassEffect */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-color: #186bdd;
}

.circle .icon {
  font-size: 2rem;
  transition: color 0.3s ease-in-out;
}

.circle:hover .icon {
  color: #186bdd;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* imagenperfil */
.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.circle-image {
  width: 50%;
  max-width: 300px;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
